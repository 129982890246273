import { getAvatarImage } from '@/lib/getAvatarImage'
import { getCurrentUsersPlan } from '@/lib/getCurrentUsersPlan'
import { User } from '@/types/user'
import { Avatar, AvatarBadge, Tooltip, AvatarProps } from '@chakra-ui/react'

type Props = {
  border?: string
  borderColor?: string
  user: User
  size?: keyof typeof sizes
}

const sizes = {
  sm: 0.65,
  md: 1,
  lg: 1.5,
}

export const UserAvatar = (props: Props) => {
  if (!props.user) return null

  const defaultProps = {
    height: 12,
    name: props.user.name || props.user.first_name,
    width: 12,
    src: props.user.avatar && getAvatarImage(props.user.avatar),
  }

  const avatarProps = { ...defaultProps, ...props } as AvatarProps
  const plan = getCurrentUsersPlan(props.user, { format: 'word' })
  const showBadge = plan === 'Builder'
  const { size = 'md' } = props

  return (
    <Avatar {...avatarProps} style={{ transform: `scale(${size})` }}>
      {showBadge && (
        <AvatarBadge
          bg='transparent'
          color='white'
          border='none'
          right='4px'
          bottom='4px'>
          <Tooltip
            label='Builder'
            hasArrow
            borderRadius={3}
            fontSize='xs'
            backgroundColor='blue.800'
            textTransform='uppercase'
            fontWeight='bold'>
            <img src='/img/wrench.svg' height={18} width={18} alt={plan} />
          </Tooltip>
        </AvatarBadge>
      )}
    </Avatar>
  )
}
