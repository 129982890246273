import { useAuth } from '@/hooks/auth'
import { User } from '@/types/user'
import { createContext } from 'react'

type Protection = { guarded?: boolean }

// Data for currently logged in user
export const UserData = (protection?: Protection): User => {
  let middleware = 'guest'

  if (protection?.guarded) middleware = 'auth'

  const { user }: { user: User } = useAuth({
    middleware,
    redirectIfAuthenticated: false,
  })

  return user || null
}

export const UserContext = createContext(UserData)
