import { useAuth } from '@/hooks/auth'
import { isBuilder } from '@/lib/isBuilder'
import { isPaidMember } from '@/lib/isPaidMember'
import { UserContext } from '@/lib/UserContext'
import {
  Box,
  Link,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
} from '@chakra-ui/react'
import NextLink from 'next/link'
import { useContext, useEffect, useRef, useState } from 'react'
import { UserAvatar } from './UserAvatar'

type MenuItemType = {
  onClick?: () => void
  url: string
  text: string
} | null

export const NavDashboard = () => {
  const userData = useContext(UserContext)
  let user = userData()
  const { logout } = useAuth()
  let menuSet = useRef(false)

  const [menu, setMenu] = useState<MenuItemType[]>([
    {
      url: '/dashboard',
      text: 'Dashboard',
    },
    {
      url: '/dashboard/projects',
      text: 'Vehicles',
    },
  ])

  useEffect(() => {
    if (!user) return
    if (menuSet.current) return

    let newMenuItems: MenuItemType[] = []

    if (isBuilder(user)) {
      newMenuItems.push({
        url: '/dashboard/garage',
        text: 'Pro Shop',
      })
    }
    if (isPaidMember(user)) {
      newMenuItems.push({
        url: `/dashboard/chat/${user.uuid}`,
        text: 'Chat',
      })
    }
    if (isPaidMember(user)) {
      newMenuItems.push({
        url: '/dashboard/discounts',
        text: 'Discounts',
      })
    }
    setMenu(prev => [
      ...prev,
      ...newMenuItems,
      {
        url: '/dashboard/comments',
        text: 'Comments',
      },
      {
        url: '/dashboard/account',
        text: 'Account',
      },
    ])
    menuSet.current = true
  }, [user, menu])

  return (
    <Menu>
      <MenuButton>
        <UserAvatar user={user} />
      </MenuButton>
      <MenuList
        bgColor='gray.100'
        border='none'
        color='gray.900'
        display='grid'
        gridTemplateColumns={{ base: '1fr', lg: '1fr 1fr 1fr' }}
        maxWidth='320px'
        mt={3}
        p={8}
        gridGap={4}>
        <MenuGroup>
          <Box
            width={4}
            height={4}
            backgroundColor='gray.100'
            position='absolute'
            top={2}
            right={4}
            sx={{ transform: 'rotate(45deg)' }}
          />
          {menu &&
            menu.map((item, i) => (
              <MenuItem
                key={i}
                display={
                  item?.text == 'Dashboard'
                    ? { base: 'block', sm: 'none' }
                    : 'block'
                }>
                {item?.url ? (
                  <NextLink
                    href={item.url}
                    passHref={item.url ? true : false}
                    legacyBehavior>
                    <Link whiteSpace='nowrap' onClick={item.onClick}>
                      {item.text}
                    </Link>
                  </NextLink>
                ) : null}
              </MenuItem>
            ))}
          <MenuItem>
            <Link onClick={logout}>Logout</Link>
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  )
}
