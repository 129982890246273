import axios from '@/lib/axios'
import { useToast } from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'
import useSWR from 'swr'

const csrf = () => axios.get('/sanctum/csrf-cookie')

const forgotPassword = async ({ setErrors, setStatus, email }) => {
  await csrf()

  setErrors([])
  setStatus('Sending…')

  axios
    .post('/forgot-password', { email })
    .then(response => setStatus(response.data.status))
    .catch(error => {
      if (error?.response?.status != 422) throw error
      setStatus(false)
      setErrors(Object.values(error?.response?.data.errors).flat())
    })
}

const resendEmailVerification = ({ setStatus, setErrors }) => {
  axios
    .post('/email/verification-notification')
    .then(response => setStatus(response.data.status))
    .catch(error => {
      if (error.response.status != 422) throw error
      setStatus(false)
      setErrors(Object.values(error.response.data.errors).flat())
    })
}

export const useAuth = ({ middleware, redirectIfAuthenticated } = {}) => {
  const router = useRouter()
  const toast = useToast()

  const {
    data: user,
    error,
    revalidate,
  } = useSWR('/api/user', () => {
    const user = axios
      .get('/api/user')
      .then(res => res.data)
      .catch(error => {
        if (error?.response?.status != 409) throw error
        router.push('/verify-email')
      })
    return user
  })

  const register = async ({ setErrors, ...props }) => {
    await csrf()

    setErrors([])

    axios
      .post('/register', props)
      .then(() => {
        typeof revalidate === 'function' && revalidate()
      })
      .catch(error => {
        if (error?.response?.status != 422) throw error

        setErrors(Object.values(error?.response?.data.errors).flat())
      })
  }

  const login = async ({ setErrors, setStatus, setSubmitting, ...props }) => {
    await csrf()

    setErrors([])
    setStatus(null)

    axios
      .post('/login', props)
      .then(() => {
        if (typeof revalidate == 'function') revalidate()
        setSubmitting(false)
      })
      .catch(error => {
        if (!error?.response) return
        if (error?.response?.status != 422) throw error
        setSubmitting(false)
        setErrors(Object.values(error?.response?.data.errors).flat())
      })
  }

  const resetPassword = async ({
    setErrors,
    setStatus,
    setSubmitting,
    ...props
  }) => {
    await csrf()

    setErrors([])
    setStatus(null)
    setSubmitting(true)

    if (props.password != props.password_confirmation) {
      setErrors(['Passwords do not match'])
      setSubmitting(false)
      return
    }

    axios
      .post('/reset-password', { token: router.query.token, ...props })
      .then(response => {
        setSubmitting(false)
        router.push('/login?reset=' + btoa(response.data.status))
      })
      .catch(error => {
        setSubmitting(false)
        setErrors(Object.values(error?.response?.data.errors).flat())
        if (error?.response?.status != 422) throw error
      })
  }

  const logout = useCallback(async () => {
    if (!error) {
      toast({
        title: 'Logging out.',
        description: 'Just a moment…',
        status: 'success',
        duration: 9000,
        isClosable: true,
      })
      await axios.post('/logout')
      window.location.pathname = '/login'
    } else {
      window.location.pathname = '/login'
      toast({
        title: 'There was an error trying to log you out.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      })
    }
  }, [error, toast])

  useEffect(() => {
    if (middleware == 'guest' && redirectIfAuthenticated && user)
      router.push(redirectIfAuthenticated)
    if (middleware == 'auth' && error) logout()
  }, [user, error, logout, middleware, router, redirectIfAuthenticated])

  return {
    user,
    register,
    login,
    forgotPassword,
    resetPassword,
    resendEmailVerification,
    logout,
  }
}
