import { User } from '@/types/user'

export const isPaidMember = (user: User): boolean => {
  if (!user) return false
  const plans = new Set([
    'level_2_yearly_cad_2',
    'level_2_yearly_2',
    'level_2_yearly_cad',
    'level_2_yearly',
    'level_2_monthly_cad',
    'level_2_monthly',
    'level_3_yearly_cad_2',
    'level_3_yearly_2',
    'level_3_yearly_cad',
    'level_3_yearly',
    'level_3_monthly_cad',
    'level_3_monthly',
  ])
  return plans.has(user.stripe_plan ?? '')
}
