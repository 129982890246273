import { Box, Flex } from '@chakra-ui/react'

export const ViewportSizeIndicator = () => {
  return (
    <>
      <Flex
        pos='fixed'
        bottom={3}
        left={3}
        bgColor='white'
        width={6}
        height={6}
        borderRadius='full'
        fontSize='xs'
        lineHeight={2}
        justifyContent='center'
        alignContent='center'>
        <Box display={{ base: 'none', sm: 'block', md: 'none' }}>sm</Box>
        <Box display={{ base: 'none', md: 'block', lg: 'none' }}>md</Box>
        <Box display={{ base: 'none', lg: 'block', xl: 'none' }}>lg</Box>
        <Box display={{ base: 'none', xl: 'block', '2xl': 'none' }}>xl</Box>
        <Box display={{ base: 'none', '2xl': 'block' }}>2xl</Box>
      </Flex>
    </>
  )
}
