import { UserContext } from '@/lib/UserContext'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'
import { PostHogProvider } from 'posthog-js/react'
import { useContext, useEffect } from 'react'

const shouldTrack = () => {
  return (
    process.env.NEXT_PUBLIC_POSTHOG_KEY &&
    process.env.NEXT_PUBLIC_POSTHOG_HOST &&
    process.env.NEXT_PUBLIC_ANALYTICS_DISABLED !== 'true'
  )
}

const initPostHog = () => {
  if (!shouldTrack()) return

  // Check that PostHog is client-side (used to handle Next.js SSR)
  if (typeof window !== 'undefined') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY ?? '', {
      api_host:
        process.env.NEXT_PUBLIC_POSTHOG_HOST || 'https://us.i.posthog.com',
      ui_host: 'https://us.posthog.com',
      person_profiles: 'identified_only',
      autocapture: false,
      // Enable debug mode in development
      loaded: posthog => {
        // eslint-disable-next-line testing-library/no-debugging-utils
        if (process.env.NODE_ENV === 'development') posthog.debug()
      },
    })
  }
}

const trackPageView = () => {
  if (!shouldTrack()) return
  posthog?.capture('$pageview')
}

export const trackEvent = (
  event: string,
  properties?: Record<string, unknown>
) => {
  if (!shouldTrack()) return

  posthog?.capture(event, properties)
}

type InsightsProviderProps = {
  children: React.ReactNode
}

export const InsightsProvider = (props: InsightsProviderProps) => {
  const router = useRouter()
  const user = useContext(UserContext)()

  const postHogPageViewEffect = () => {
    router.events.on('routeChangeComplete', trackPageView)

    return () => {
      router.events.off('routeChangeComplete', trackPageView)
    }
  }

  const userIdentifyEffect = () => {
    if (!user) return
    if (!shouldTrack()) return

    const userTrackData = {
      current_plan: user.current_plan,
      isPaidMember: user.isPaidMember,
      stripe_id: user.stripe_id,
      stripe_plan: user.stripe_plan,
      uuid: user.uuid,
    }

    posthog.identify(user.uuid, userTrackData)
  }

  useEffect(initPostHog, [])
  useEffect(postHogPageViewEffect, [router.events])
  useEffect(userIdentifyEffect, [user])

  return <PostHogProvider client={posthog}>{props.children}</PostHogProvider>
}
