'use client'

import { Nav } from '@/components/Nav'
import { Box } from '@chakra-ui/react'
import React from 'react'
import { Footer } from './Footer'
import { ViewportSizeIndicator } from './ViewportSizeIndicator'

export default function Layout({ children }: any) {
  return (
    <>
      <Nav />
      <Box backgroundColor='gray.150' pt='20'>
        <main>{children}</main>
      </Box>
      <Footer />
      {process.env.NODE_ENV === 'development' && <ViewportSizeIndicator />}
    </>
  )
}
