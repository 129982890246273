import Layout from '@/components/Layout'
import { UserContext, UserData } from '@/lib/UserContext'
import { ChakraProvider } from '@chakra-ui/react'
import { InsightsProvider } from 'modules/insights/Insights'
import type { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import NextNProgress from 'nextjs-progressbar'
import '../styles/global.css'
import '../styles/mapboxOverrides.css'
import '../styles/photoSwipeOverrides.css'
import { theme } from '../styles/theme'

const getUserData = () => {
  return UserData
}

function App({ Component, pageProps }: AppProps) {
  const router = useRouter()

  return (
    <UserContext.Provider value={getUserData()}>
      <InsightsProvider>
        <ChakraProvider theme={theme}>
          <Layout>
            <NextNProgress color='#81181e' options={{ showSpinner: false }} />
            <Component {...pageProps} key={router.asPath} />
          </Layout>
        </ChakraProvider>
      </InsightsProvider>
    </UserContext.Provider>
  )
}

export default App
