import {
  Box,
  Flex,
  GridItem,
  Link,
  List,
  ListItem,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/react'
import NextLink from 'next/link'

export const Footer = () => {
  let menuItemsData = [
    { title: 'Home', link: '/' },
    { title: 'Deboss Garage Merch', link: 'https://merch.debossgarage.com' },
    { title: 'About', link: '/about' },
    { title: 'Contact', link: '/contact' },
    { title: 'Become a Member', link: '/membership' },
    { title: 'Become a Partner', link: '/partner' },
    { title: 'Int’l Quarantine Car Show', link: '/iqcs2020' },
    { title: 'Internetional Car Show', link: '/ics2021' },
  ]

  let socialProfileData = [
    {
      title: 'YouTube',
      link: 'https://www.youtube.com/c/debossgarage',
      imgSrc: '/img/social-icons/Youtube.svg',
    },
    {
      title: 'Twitter',
      link: 'https://twitter.com/debossgarage',
      imgSrc: '/img/social-icons/Twitter.svg',
    },
    {
      title: 'Instagram',
      link: 'https://www.instagram.com/debossgarage/',
      imgSrc: '/img/social-icons/Instagram.svg',
    },
    {
      title: 'Facebook',
      link: 'https://www.facebook.com/groups/debossgarage',
      imgSrc: '/img/social-icons/Facebook.svg',
    },
    {
      title: 'Tiktok',
      link: 'https://www.tiktok.com/@debossgarage',
      imgSrc: '/img/social-icons/Tiktok.svg',
    },
  ]

  return (
    <footer role='footer'>
      <SimpleGrid
        bgColor='blue.900'
        color='white'
        columns={[1, 1, 2]}
        spacing={12}
        px={6}
        py={12}>
        <List display='grid' gridTemplateColumns='1fr 1fr' gap={2}>
          {menuItemsData.map((item, i) => (
            <ListItem key={i}>
              <NextLink href={item.link} passHref legacyBehavior>
                <Link>{item.title}</Link>
              </NextLink>
            </ListItem>
          ))}
        </List>
        <Box>
          <Box>
            <Flex justifyContent='flexStart'>
              {socialProfileData.map(item => (
                <Link
                  display='block'
                  mr={10}
                  rel='noreferrer'
                  href={item.link}
                  target='_blank'
                  key={item.title}>
                  <img
                    src={item.imgSrc}
                    width='32'
                    height='32'
                    alt={item.title || ''}
                    style={{ objectFit: 'contain', objectPosition: 'center' }}
                  />
                </Link>
              ))}
            </Flex>
          </Box>
        </Box>
        <Box fontSize='sm'>
          <Text>
            DEBOSS GARAGE - The #1 Engine Swap Specialist on YouTube - will show
            you all the ways to save money on your project, and make sure you
            never get in over your head.
            <NextLink href='/' passHref legacyBehavior>
              <Link>More about the channel</Link>
            </NextLink>
          </Text>
          <Text mb={2}>
            YouTuber, mechanic, all around handyman, Filthy Rich is a die hard
            dieselhead, and inventor of the Tape Boss.
          </Text>
        </Box>
        <Box fontSize='sm'>
          <Text mb={4}>
            <strong>AFFILIATE DISCLOSURE</strong> We are participants in
            affiliate advertising programs designed to provide a means for sites
            to earn advertising fees by advertising and linking debossgarage.com
            to Amazon properties, Google Adsense &amp; eBay listings.
          </Text>
        </Box>
        <GridItem colSpan={[1, 1, 2]}>
          <Stack fontSize='xs' spacing={4} direction={['column', 'row']}>
            <Text id='copyright'>&copy; Since 2016</Text>
            <Link href='http://redeggentertainment.com/'>
              RED EGG ENTERTAINMENT
            </Link>
            | made in Canada
            <NextLink href='/privacy-policy' passHref legacyBehavior>
              <Link>Privacy Policy</Link>
            </NextLink>
            <NextLink href='/refund-policy' passHref legacyBehavior>
              <Link>Refund Policy</Link>
            </NextLink>
            <NextLink href='/terms-of-service' passHref legacyBehavior>
              <Link>Terms of Service</Link>
            </NextLink>
          </Stack>
        </GridItem>
      </SimpleGrid>
    </footer>
  )
}
