import { User } from '@/types/user'

const plans = new Map<PlanSlug, { [key: string]: string }>([
  [
    'builder',
    {
      word: 'Builder',
      id: 'member-level-3',
      slug: 'builder',
    },
  ],
  [
    'apprentice',
    {
      word: 'Apprentice',
      id: 'member-level-2',
      slug: 'apprentice',
    },
  ],
  [
    'enthusiast',
    {
      word: 'Enthusiast',
      id: 'member-new',
      slug: 'enthusiast',
    },
  ],
])

export type PlanSlug = 'builder' | 'apprentice' | 'enthusiast'

type Format = 'word' | 'id' | 'slug'

export const getCurrentUsersPlan = (
  user: User,
  { format = 'word' }: { format: Format }
): string | undefined => {
  if (!user) return
  if (!user.stripe_plan) {
    const plan = plans.get('enthusiast')

    // Check that plan exists
    if (plan) {
      const formatText = plan[format]

      // Check that format exists
      if (formatText) {
        return formatText
      }
    }
  }

  const builderPlans = [
    'level_3_yearly_cad_2',
    'level_3_yearly_2',
    'level_3_yearly_cad',
    'level_3_yearly',
    'level_3_monthly_cad',
    'level_3_monthly',
  ]

  const apprenticePlans = [
    'level_2_yearly_cad_2',
    'level_2_yearly_2',
    'level_2_yearly_cad',
    'level_2_yearly',
    'level_2_monthly_cad',
    'level_2_monthly',
  ]

  if (user.stripe_plan && builderPlans.includes(user.stripe_plan)) {
    const plan = plans.get('builder')

    return plan && typeof plan[format] === 'string' ? plan[format] : undefined
  }

  if (user.stripe_plan && apprenticePlans.includes(user.stripe_plan)) {
    const plan = plans.get('apprentice')

    return plan && typeof plan[format] === 'string' ? plan[format] : undefined
  }
}
