import { extendTheme } from '@chakra-ui/react'
import { withProse } from '@nikolovlazar/chakra-ui-prose'

export const theme = extendTheme(withProse(), {
  styles: {
    global: {
      body: {
        bg: 'gray.900',
        fontFamily:
          'Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
      },
    },
  },
  components: {
    Button: {
      baseStyle: {
        borderRadius: '0',
        textTransform: 'uppercase',
      },
      sizes: {
        md: {
          fontSize: 'xs',
          px: 4,
          py: 5,
          letterSpacing: '.035em',
        },
      },
      variants: {
        dark: {
          bg: 'blue.800',
          color: 'white',
          _hover: {
            bg: 'blue.700',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderColor: 'gray.200',
        },
      },
      defaultProps: {
        colorScheme: 'red',
        size: 'lg',
      },
    },
    Menu: {
      baseStyle: {
        bg: '#021129',
        item: {
          _focus: {
            bg: 'transparent',
          },
        },
      },
    },
    Heading: {
      baseStyle: {
        fontFamily:
          '"Bebas Neue", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
        fontWeight: 900,
        lineHeight: 1,
        // letterSpacing: `-.0275em`,
      },
    },
  },
  fontSizes: {
    xs: '0.75rem',
    sm: '0.875rem',
    base: '1rem',
    lg: '1.125rem',
    xl: '1.5rem',
    '2xl': '2.5rem',
    display1: '4.5rem',
    display2: '3.75rem',
    display3: '2.25rem',
  },
  borderWidth: {
    1: '1px',
    4: '4px',
  },
  colors: {
    gray: {
      100: '#F4F4F4',
      150: '#e8e8e8',
      200: '#cccdcb',
      300: '#a7a7a5',
      400: '#81817e',
      500: '#676765',
      600: '#4d4d4c',
      700: '#333432',
      800: '#1a1a19',
      900: '#0d0d0d',
    },
    red: {
      100: '#fbeaea',
      200: '#e77e84',
      300: '#de545b',
      400: '#C10D38',
      500: '#A20F32',
      600: '#81181e',
      700: '#561014',
      800: '#2b080a',
      900: '#150405',
    },
    green: {
      100: '#eff5f2',
      200: '#dfece4',
      300: '#c0d8ca',
      400: '#4f8264',
      500: '#3a5f49',
      600: '#273f31',
      700: '#1d2f24',
      800: '#3F0333',
      900: '#0a100c',
    },
    blue: {
      900: '#021129',
    },
    cyan: {
      400: '#00AFB9',
    },
    yellow: {
      50: false,
      100: false,
      200: false,
      300: '#fef9a9',
      400: false,
      500: false,
      600: false,
      700: '#ffc700',
      800: false,
      900: false,
    },
    transparent: 'transparent',
    black: '#021129',
    white: '#ffffff',
    inherit: 'inherit',
    google: '#4285F4',
    facebook: '#4267B2',
    twitter: '#1DA1F2',
  },
  maxWidth: {
    32: '8rem',
  },
  shadows: { outline: 'none' },
  zIndex: {
    '-1': '-1',
  },
})
